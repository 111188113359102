import React, { useMemo } from 'react';
import { AlertCircle, Plus, Upload } from 'lucide-react';

const BILL_TYPES = [
    { value: 'electricity', label: 'Electricity bill' },
    { value: 'water', label: 'Water bill' },
    { value: 'stationery', label: 'Stationery' },
    { value: 'internet', label: 'Internet' },
    { value: 'house_keeping', label: 'House Keeping' }
];

const BillManagementUI = ({ employeeAddSection, toggleModal, handleUpload, isUploading, formData }) => {
    const unselectedBills = useMemo(() => {
        const selectedTypes = formData.map(item => item.type);
        return BILL_TYPES.filter(bill => !selectedTypes.includes(bill.value))
            .map(bill => bill.label);
    }, [formData]);

    const remainingBillsCount = unselectedBills.length;

    return (
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-2xl font-bold text-white mb-4 text-center">
                Manage Your Bills
            </h2>

            {remainingBillsCount > 0 && (
                <div className="mb-4 p-4 bg-yellow-900 border-l-4 border-yellow-500 text-yellow-100 rounded">
                    <div className="flex items-center">
                        <AlertCircle className="h-5 w-5 mr-2" />
                        <p className="font-semibold">Remaining Bill Types</p>
                    </div>
                    <p className="mt-2">
                        You have {remainingBillsCount} bill type{remainingBillsCount > 1 ? 's' : ''} remaining to select:
                    </p>
                    <ul className="list-disc list-inside mt-2">
                        {unselectedBills.map((bill, index) => (
                            <li key={index}>{bill}</li>
                        ))}
                    </ul>
                </div>
            )}

            <p className="text-gray-300 mb-6 text-center">
                {remainingBillsCount > 0
                    ? "Select more bill types or proceed with uploading the current selections."
                    : "All bill types are selected. You can now upload and analyze them."}
            </p>

            <div className="space-y-4">
                <button
                    onClick={() => { toggleModal(); employeeAddSection(); }}
                    className="w-full px-3 py-2 rounded-md text-gray-800 font-medium
           transition-colors bg-white hover:bg-gray-100
           flex items-center justify-center space-x-2 text-sm"
                    aria-live="polite"
                >
                    <Plus size={16} />
                    <span>Select Another Bill Type</span>
                </button>
                <button
                    onClick={() => { handleUpload(); toggleModal(); }}
                    disabled={isUploading}
                    className={`w-full px-4 py-3 rounded-md text-white font-semibold
              transition-colors flex items-center justify-center space-x-2
              ${isUploading
                            ? 'bg-gray-500 cursor-not-allowed'
                            : 'bg-green-600 hover:bg-green-700'
                        }`}
                    aria-live="polite"
                >
                    <Upload size={20} />
                    <span>{isUploading ? 'Uploading...' : 'Upload and Analyze'}</span>
                </button>
            </div>

            {remainingBillsCount > 0 && (
                <p className="text-sm text-gray-400 mt-4 text-center">
                    You can still upload your currently selected bills and add more later.
                </p>
            )}
        </div>
    );
};

export default BillManagementUI;