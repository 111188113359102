import { CircleCheckBig, Plus, Trash } from "lucide-react";
import React, { useEffect, useState } from "react";
import { uploadHubSetupBill } from "../../services/hubsetup";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const initialFormState = {
    employee_id: "",
    hub_name: ""
};

const HubSetupForm = () => {

    const [searchParams] = useSearchParams();
    const [formData, setFormData] = useState(initialFormState);
    const [expenses, setExpenses] = useState([{ amount: '', date: '' ,user_remarks: '' }]);
    const [hubs, setHubs] = useState([])
    const [uploadStatus, setUploadStatus] = useState("");
    const [currentStep, setCurrentStep] = useState(1);
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleNextClick = () => {
        const errors = {};
        if (!formData.employee_id.trim()) {
            errors.employee_id = "Employee ID is required";
        }
        if (!formData.hub_name.trim()) {
            errors.hub_name = "Hub Name is required";
        }

        if (Object.keys(errors).length === 0) {
            setCurrentStep(2);
            setFormErrors({});
        } else {
            setFormErrors(errors);
        }
    };

    const addExpense = () => {
        setExpenses([...expenses, { amount: '', user_remarks: '' }]);
    };

    const removeExpense = (index) => {
        const updatedExpenses = expenses.filter((_, i) => i !== index);
        setExpenses(updatedExpenses);
    };

    const handleExpenseChange = (index, e) => {
        const { name, value } = e.target;
        const updatedExpenses = expenses.map((expense, i) => {
            if (i === index) {
                return { ...expense, [name]: value };
            }
            return expense;
        });
        setExpenses(updatedExpenses);
    };

    const handleUpload = async () => {
        setIsLoading(true);
        setError(null);
        setUploadStatus("Uploading...");

        const payload = {
            ...formData,
            type: "setup_reimbursement",
            expenses: expenses
        };

        try {
            await uploadHubSetupBill(payload);
            setCurrentStep(3);
            setUploadStatus("Upload successful");
        } catch (error) {
            setError("Upload failed. Please try again.");
            setUploadStatus("");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const id = searchParams.get("id");
            if (id) {
                try {
                    const response = await axios.get(
                        `${BASE_URL}/employee/${id}`,
                        {
                            headers: {
                                "ngrok-skip-browser-warning": "true",
                            },
                        }
                    );
                    const userData = response.data.data;

                    setFormData((prevState) => ({
                        ...prevState,
                        employee_id: userData?.id || "",
                        name: userData?.name
                    }));
                    let designation = userData?.designation;
                    let hubRole = ""

                    if (!designation || designation === 'Hub Incharge') hubRole = 'hubsInCharge';
                    else if (designation === 'Level 1') hubRole = "hubsLevel1";
                    else hubRole = "hubsLevel2"

                    // let hubIds = userData[hubRole]
                    console.log(userData[hubRole], "--- user data ---")
                    setHubs(userData[hubRole]);
                } catch (err) {
                    toast.error('Failed to fetch Employee data. Please try again.')
                    console.error("Error fetching user data:", err);
                }
            }
            setIsLoading(false);
        };

        fetchUserData();
    }, [searchParams]);

    const renderReviewInformationForm = () => (
        <>
            <h3 className="text-xl font-medium mb-4 text-gray-200">
                <p className="text-sm font-medium text-gray-100 opacity-65" style={{ fontSize: "0.75rem" }}>
                    Step 1 of 2
                </p>
                Enter Your Information
            </h3>
            {isLoading ? (
                <p className="text-white">Loading user data...</p>
            ) : !formData.employee_id ? (
                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                    <p className="font-bold">Employee Information Not Found</p>
                    <p>You must be part of the organization to submit a reimbursement request. Please contact your administrator for assistance.</p>
                </div>
            ) : (
                <div className="space-y-4 mb-6">
                    <div>
                        <label htmlFor="employee_id" className="block text-sm font-medium text-gray-300 mb-1">
                            Employee ID
                        </label>
                        <input
                            type="text"
                            id="employee_id"
                            name="employee_id"
                            value={formData.employee_id}
                            onChange={handleInputChange}
                            className={`block w-full bg-[#1F1F1F] border ${formErrors.employee_id ? "border-red-500" : "border-[#373737]"} rounded-md shadow-sm p-2 text-white`}
                        />
                        {formErrors.employee_id && (
                            <p className="mt-1 text-sm text-red-500">{formErrors.employee_id}</p>
                        )}
                    </div>
                    <div>
                        <label
                            htmlFor="hub_name"
                            className="block text-sm font-medium text-gray-300 mb-1"
                        >
                            Hub
                        </label>
                        {hubs && hubs.length > 0 ? (
                            <select
                                id="hub_name"
                                name="hub_name"
                                value={formData.hub_name}
                                onChange={handleInputChange}
                                className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                            >
                                <option value="">Select a hub</option>
                                {hubs.map((hub) => (
                                    <option key={hub.id} value={hub.id}>
                                        {`${hub.location} (${hub.state} - ${hub.zone})`}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                                <p className="font-bold">No Hubs Assigned</p>
                                <p>There are no hubs attached to your name. Please contact your administrator for assistance.</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {formData.employee_id && (
                <button
                    onClick={handleNextClick}
                    disabled={!formData.employee_id || !formData.hub_name || isLoading}
                    className={`w-full px-4 py-2 rounded-md transition-colors ${formData.employee_id && formData.hub_name && !isLoading
                        ? 'bg-white text-black hover:bg-blue-700'
                        : 'bg-white opacity-25 text-gray-600 cursor-not-allowed'
                        }`}
                >
                    Next
                </button>
            )}
        </>
    );

    const renderHubSetupBillForm = () => (
        <>
            <h3 className="text-xl font-medium mb-4 text-gray-200 flex flex-col gap-6">
                <div className="flex w-full justify-between items-center">
                    <p className="text-sm font-regular text-gray-100 opacity-65">
                        Step 2 of 2
                    </p>
                </div>
                Add Bills
            </h3>
            {expenses.map((expense, index) => (
                <div key={index} className="mb-8 p-4 border border-gray-700 rounded-lg">
                    {expenses.length > 1 && (
                        <button
                            onClick={() => removeExpense(index)}
                            disabled={isLoading}
                            className="p-1.5 bg-white rounded-full text-black hover:bg-white transition-colors mb-6"
                        >
                            <Trash size={16} />
                        </button>
                    )}
                    <div className="mb-4">
                        <label htmlFor={`amount_${index}`} className="block text-sm font-medium text-gray-300 mb-1">
                            Amount
                        </label>
                        <input
                            type="number"
                            id={`amount_${index}`}
                            name="amount"
                            value={expense.amount}
                            onChange={(e) => handleExpenseChange(index, e)}
                            className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                            placeholder="Enter Amount"
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            htmlFor="date"
                            className="block text-sm font-medium text-gray-300 mb-1"
                        >
                            Date
                        </label>
                        <input
                            type="date"
                            id={`date_${index}`}
                            name="date"
                            value={expense.date}
                            onChange={(e) => handleExpenseChange(index, e)}
                            className={`block w-full bg-[#1F1F1F] border ${expense.date ? "border-red-500" : "border-[#373737]"
                                } rounded-md shadow-sm p-2 text-white`}
                            max={new Date().toISOString().split('T')[0]} // Set max to the current date
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor={`user_remarks_${index}`} className="block text-sm font-medium text-gray-300 mb-1">
                            Remarks
                        </label>
                        <textarea
                            id={`user_remarks_${index}`}
                            name="user_remarks"
                            value={expense.user_remarks}
                            onChange={(e) => handleExpenseChange(index, e)}
                            className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
                            placeholder="Enter Remarks"
                        ></textarea>
                    </div>
                </div>
            ))}
            <div className="flex flex-col space-y-4 mb-3 mt-6">
                <button
                    type="button"
                    className="text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-2.5 py-1.5 text-center inline-flex items-center justify-center"
                    onClick={addExpense}
                    disabled={isLoading}
                >
                    <Plus className="w-5 h-5 mr-2" />
                    Add Bill
                </button>
            </div>
            <button
                onClick={handleUpload}
                disabled={isLoading || expenses.length === 0 || expenses.some(e => !e.amount || !e.user_remarks)}
                className={`w-full px-4 py-2 rounded-md transition-colors ${isLoading || expenses.length === 0 || expenses.some(e => !e.amount || !e.user_remarks)
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-green-600 hover:bg-green-700'
                    } text-white`}
            >
                {isLoading ? 'Submitting...' : 'Submit'}
            </button>
            {error && <p className="mt-2 text-red-500">{error}</p>}
            {uploadStatus && <p className="mt-2 text-green-500">{uploadStatus}</p>}
        </>
    );

    const renderSuccess = () => (
        <div className="w-full h-screen flex flex-col gap-28 pt-10 animate-slide-in-right">
            <div className="flex flex-col items-center gap-6">
                <CircleCheckBig className="w-16 h-16 text-white animate-bounce" />
                <p className="text-2xl ">Completed</p>
            </div>
            <p className="text-white text-center">You have successfully submitted your information.</p>
        </div>
    );

    return (
        <div className="max-w-md mx-auto p-6 bg-[#191919] rounded-lg shadow-xl text-gray-100">
            <h2 className="text-[16px] font-medium mb-6 text-center text-white-400">
                DeliveryPlus - Hub Setup
            </h2>
            {currentStep === 1 && renderReviewInformationForm()}
            {currentStep === 2 && renderHubSetupBillForm()}
            {currentStep === 3 && renderSuccess()}
        </div>
    );
};

export default HubSetupForm;