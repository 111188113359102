import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  //  AlertTriangle,
  ArrowLeft,
  CircleCheckBig,
  Image,
  XCircleIcon,
} from "lucide-react";
import axios from "axios";
import {
  getBase64FromDataURL,
  initialFormState,
  readFileAsDataURL,
} from "./helper";
import { analyzeImage } from "../../services/cod";
import { API_URL } from "../../config";
import CustomCalendarPicker from "./CustomCalendarPicker";
import "react-datepicker/dist/react-datepicker.css";

const Cod = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState(initialFormState);
  const [preview, setPreview] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [analysisResult, setAnalysisResult] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [amountMismatch, setAmountMismatch] = useState(false);
  const [, setConfirmationStep] = useState(false);
  // const [dateMismatch, setDateMismatch] = useState(false);
  // const [slipNumberMismatch, setSlipNumberMismatch] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [hubs, setHubs] = useState([]);
  const [selectedHubPendingAmount, setSelectedHubPendingAmount] = useState(0);
  const [showResaonForNoDiposit, setShowReasonForNoDiposit] = useState(false);
  const [dateFromHubs, setDateFromHubs] = useState([]);
  console.log(dateFromHubs);
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchUserData = async () => {
      const id = searchParams.get("id");
      console.log(id);

      if (id) {
        try {
          const response = await axios.get(`${BASE_URL}/ocr/fetchuser/${id}`, {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          });
          const userData = response?.data;

          setHubs(userData.hubs);
          setDateFromHubs(
            userData.hubs[0].date_deposit.sort(
              (a, b) => new Date(a) - new Date(b)
            )
          );
          setFormData((prevState) => ({
            ...prevState,
            employee_id: id || "",
            hub: userData?.hubs[0]?.id || "",
            // overall_pending_amount: userData?.hubs[0]?.overall_pending_amount
          }));
          console.log(userData.hubs[0]?.overall_pending_amount);
          if (userData?.hubs?.length > 0) {
            setSelectedHubPendingAmount(
              userData.hubs[0]?.overall_pending_amount || 0
            );
          }
        } catch (err) {
          setError(
            "Failed to fetch user data. Please fill in the form manually."
          );
          console.error("Error fetching user data:", err);
        }
      }
      if (id) {
        setFormData((prevState) => ({
          ...prevState,
          employee_id: id,
        }));
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [searchParams, BASE_URL]);

  // const formatDateForDisplay = (dateString) => {
  //   if (!dateString) return "";
  //   const date = new Date(dateString);
  //   return date
  //     .toLocaleDateString("en-GB", {
  //       day: "2-digit",
  //       month: "2-digit",
  //       year: "numeric",
  //     })
  //     .replace(/\//g, "/");
  // };

  const validateForm = () => {
    const errors = {};
    if (!formData.employee_id.trim())
      errors.employee_id = "Employee ID is required";

    if (!formData.amount?.trim()) errors.amount = "Amount  is required";

    if (isNaN(formData.amount) || parseFloat(formData.amount) <= 0)
      errors.amount = "Please enter a valid amount";
    if (!formData.hub.trim()) errors.hub = "Hub is required";
    // if (!formData.account.trim()) errors.account = "Account is required";
    if (!formData.payment_method)
      errors.payment_method = "Payment method is required";
    if (!formData.date) errors.date = "Date is required";
    if (!formData.slip_number.trim())
      errors.slip_number = "Slip Number is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const valiDateFrom2 = () => {
    const errors = {};
    if (!formData.employee_id.trim())
      errors.employee_id = "Employee ID is required";
    if (!formData.amount?.trim()) errors.amount = "Amount  is required";
    if (!formData.hub.trim()) errors.hub = "Hub is required";
    if (!formData.date) errors.date = "Date is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log("handleChange", name, value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Update the pending amount when the hub is changed
    if (name === "hub") {
      const selectedHub = hubs.find((hub) => hub.id === value);
      setSelectedHubPendingAmount(
        selectedHub ? selectedHub?.overall_pending_amount || 0 : 0
      );
      console.log(selectedHub);
      setDateFromHubs(
        selectedHub?.date_deposit?.length > 0
          ? selectedHub.date_deposit.sort((a, b) => new Date(a) - new Date(b))
          : null
      );
    }

    if (name === "amount" && parseInt(value) === 0) {
      setShowReasonForNoDiposit(true);
    } else if (name === "amount" && parseInt(value) !== 0) {
      setShowReasonForNoDiposit(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        image: file,
      }));
      const dataURL = await readFileAsDataURL(file);
      setPreview(dataURL);
    } else {
      setPreview(null);
    }
  };

  const handleNextClick = () => {
    console.log(formData);
    if (validateForm()) {
      setCurrentStep(2);
    }
  };
  const handleSubmiButtonWithoutImage = () => {
    if (valiDateFrom2()) {
      const newData = {
        amount: formData.amount,
        employee_id: formData.employee_id,
        hub: formData.hub,
        absent_remarks: formData.reason_for_no_deposit,
        date: formData.date,
      };

      axios
        .post(`${API_URL}/ocr/analyze`, newData)
        .then((res) => {
          console.log(res.data);
          setCurrentStep(3);
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(newData);
    }
  };
  const handleUpload = async () => {
    if (!formData.image) {
      setUploadStatus("Please select an image first.");
      return;
    }

    setUploadStatus("Uploading...");
    setAnalysisResult(null);
    setIsUploading(true);

    setIsAnalyzing(true);

    try {
      const dataURL = await readFileAsDataURL(formData.image);
      const base64Image = getBase64FromDataURL(dataURL);
      const result = await analyzeImage(formData, base64Image);
      setUploadStatus("Upload successful! Analysis complete.");
      setAnalysisResult(result);

      // Check if the amounts match
      // const userAmount = parseFloat(formData.amount);
      // const detectedAmount = parseFloat(result.llmResponse.finalTotalAmount);
      // setAmountMismatch(Math.abs(userAmount - detectedAmount) > 0.01);

      // Check if the dates match
      // const userDate = new Date(formData.date).toISOString().split("T")[0];
      // const detectedDate = new Date(result.llmResponse.date)
      //   .toISOString()
      //   .split("T")[0];
      // setDateMismatch(userDate !== detectedDate);

      // Check if the slip numbers match
      // const userSlipNumber = formData.slip_number.trim();
      // const detectedSlipNumber =
      //   result.textractData.keyValuePairs["Deposit Slip Nos. :"]?.trim() || "";
      // setSlipNumberMismatch(userSlipNumber !== detectedSlipNumber);

      setConfirmationStep(true);
      setCurrentStep(3);
    } catch (error) {
      setUploadStatus("Upload failed. Please try again.");
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading(false);
      setIsAnalyzing(false);
    }
  };

  const resetForm = () => {
    if (isUploading || isAnalyzing) return;
    const id = searchParams.get("id");
    setFormData({
      ...initialFormState,
      employee_id: id || "",
    });
    setPreview(null);
    setUploadStatus("");
    setAnalysisResult(null);
    setCurrentStep(1);
    setFormErrors({});
  };
  const resetImageUpload = () => {
    if (isUploading || isAnalyzing) return;
    setFormData((prevState) => ({
      ...prevState,
      image: null,
    }));
    setPreview(null);
    setUploadStatus("");
    setAnalysisResult(null);
  };

  // const handleConfirmation = async (isCorrect) => {
  //   console.log(analysisResult, analysisResult.dbInsertResult);

  //   if (!analysisResult || !analysisResult.dbInsertResult) {
  //     console.error("No analysis result available");
  //     return;
  //   }

  //   const { id } = analysisResult.dbInsertResult;

  //   try {
  //     const response = await axios.post(
  //       `${API_URL}/ocr/update-employee-confirmation`,
  //       {
  //         id,
  //         isConfirmed: isCorrect,
  //         amountMismatch,
  //         dateMismatch,
  //         slipNumberMismatch,
  //       }
  //     );

  //     if (response.data) {
  //       console.log("Employee confirmation updated successfully");
  //       // You can add additional logic here, such as showing a success message
  //       // or navigating to a different page
  //     }
  //   } catch (error) {
  //     console.error("Error updating employee confirmation:", error);
  //     // Handle the error, perhaps by showing an error message to the user
  //   }

  //   // After handling the confirmation, you can reset the form or navigate to a new page
  //   resetForm();
  // };
  console.log(formData.account, "account");
  const renderReviewInformation = () => (
    <>
      <h3 className="text-xl font-medium mb-4 text-gray-200">
        <p
          className="text-sm font-medium text-gray-100 opacity-65"
          style={{ fontSize: "0.75rem" }}
        >
          Step 1 of 2
        </p>
        Review Your Information
      </h3>
      {isLoading ? (
        <p className="text-white">Loading user data...</p>
      ) : (
        <>
          {error && <p className="text-yellow-500 mb-4">{error}</p>}
          <div className="space-y-4 mb-6">
            <div>
              <label
                htmlFor="employee_id"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Employee ID
              </label>
              <input
                type="text"
                id="employee_id"
                name="employee_id"
                readOnly
                value={formData.employee_id}
                onChange={handleInputChange}
                className={`block w-full bg-[#1F1F1F] border ${formErrors.employee_id ? "border-red-500" : "border-[#373737]"
                  } rounded-md shadow-sm p-2 text-white`}
              />
              {formErrors.employee_id && (
                <p className="mt-1 text-sm text-red-500">
                  {formErrors.employee_id}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="hub"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Choose Hub
              </label>
              {hubs.length > 0 ? (
                <select
                  id="hub"
                  name="hub"
                  value={formData.hub}
                  onChange={handleInputChange}
                  className={`block w-full bg-[#1F1F1F] border ${formErrors.hub ? "border-red-500" : "border-[#373737]"
                    } rounded-md shadow-sm p-2 text-white`}
                >
                  <option>--Select Hub--</option>

                  {hubs.map((hub) => (
                    <>
                      <option key={hub.id} value={hub.id}>
                        {hub.location}
                      </option>
                    </>
                  ))}
                </select>
              ) : (
                <input
                  id="hub"
                  name="hub"
                  onChange={handleInputChange}
                  className={`block w-full bg-[#1F1F1F] border ${formErrors.hub ? "border-red-500" : "border-[#373737]"
                    } rounded-md shadow-sm p-2 text-white`}
                />
              )}

              {formErrors.hub && (
                <p className="mt-1 text-sm text-red-500">{formErrors.hub}</p>
              )}
            </div>
            {/*!showResaonForNoDiposit && <div>
              <label
                htmlFor="account"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Account
              </label>

                <select
                  name="account"
                  id="account"
                  onChange={handleInputChange}
                  value={formData.account}
                  className={`block w-full bg-[#1F1F1F] border ${
                    formErrors.account ? "border-red-500" : "border-[#373737]"
                  } rounded-md shadow-sm p-2 text-white`}
              >
                <option value="">--Select Account--</option>
                <option value="Flipkart">Flipkart</option>
              </select>
              {formErrors.account && (
                <p className="mt-1 text-sm text-red-500">
                  {formErrors.account}
                </p>
              )}
            </div>*/}
            <div>
              <label
                htmlFor="amount"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Amount
              </label>
              <input
                type="number"
                id="amount"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                className={`block w-full bg-[#1F1F1F] border ${formErrors.amount ? "border-red-500" : "border-[#373737]"
                  } rounded-md shadow-sm p-2 text-white`}
              />
              {formErrors.amount && (
                <p className="mt-1 text-sm text-red-500">{formErrors.amount}</p>
              )}
            </div>
            {showResaonForNoDiposit && (
              <div>
                <label
                  htmlFor="reason_for_no_deposit"
                  className="block text-sm font-medium text-gray-300 mb-1"
                >
                  Reason For No Deposit
                </label>

                <select
                  id="reason_for_no_deposit"
                  name="reason_for_no_deposit"
                  value={formData.reason_for_no_deposit}
                  onChange={handleInputChange}
                  className="block w-full bg-[#1F1F1F] border border-[#373737] rounded-md shadow-sm p-2 text-white"
                >
                  <option value="">Select a reason</option>
                  <option value="absent_today">Absent Today</option>
                  <option value="cms_not_available">CMS Not Available</option>
                </select>
              </div>
            )}
            <div>
              <label
                htmlFor="amount"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Pending Amount
              </label>
              <input
                type="number"
                id="pending"
                name="pending"
                value={selectedHubPendingAmount}
                // onChange={handleInputChange}
                className={`block w-full bg-[#1F1F1F] border 
                   ${formErrors?.amount ? "border-red-500" : "border-[#373737]"
                  } rounded-md shadow-sm p-2 text-white`}
                readOnly
                style={{ cursor: "not-allowed" }}
                disabled
              />
            </div>

            {!showResaonForNoDiposit && (
              <>
                <div>
                  <label
                    htmlFor="payment_method"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Payment Method
                  </label>
                  <select
                    id="payment_method"
                    name="payment_method"
                    value={formData.payment_method}
                    onChange={handleInputChange}
                    className={`block w-full bg-[#1F1F1F] border ${formErrors.payment_method
                      ? "border-red-500"
                      : "border-[#373737]"
                      } rounded-md shadow-sm p-2 text-white`}
                  >
                    <option value="">Select a method</option>
                    <option value="airtel">Airtel</option>
                    <option value="UPI">Bank Deposit</option>
                    <option value="axis_bank">Axis Bank</option>
                    {/*<option value="Spice Money">Spice Money</option>*/}
                    <option value="fino">Fino</option>
                    <option value="radiant">Radiant</option>
                    <option value="cms">CMS</option>
                    <option value="relipay">Relipay</option>
                    <option value="paynearby">PayNearby</option>

                  </select>
                  {formErrors.payment_method && (
                    <p className="mt-1 text-sm text-red-500">
                      {formErrors.payment_method}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="slip_number"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Slip Number
                  </label>
                  <input
                    type="text"
                    id="slip_number"
                    name="slip_number"
                    value={formData.slip_number}
                    onChange={handleInputChange}
                    className={`block w-full bg-[#1F1F1F] border ${formErrors.slip_number
                      ? "border-red-500"
                      : "border-[#373737]"
                      } rounded-md shadow-sm p-2 text-white`}
                  />
                  {formErrors.slip_number && (
                    <p className="mt-1 text-sm text-red-500">
                      {formErrors.slip_number}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="form_remarks"
                    className="block text-sm font-medium text-gray-300 mb-1"
                  >
                    Remarks (Optional)
                  </label>
                  <textarea
                    id="form_remarks"
                    name="form_remarks"
                    value={formData.form_remarks}
                    onChange={handleInputChange}
                    className={`block w-full bg-[#1F1F1F] border ${formErrors.form_remarks
                      ? "border-red-500"
                      : "border-[#373737]"
                      } rounded-md shadow-sm p-2 text-white`}
                    rows="3"
                  />
                  {formErrors.form_remarks && (
                    <p className="mt-1 text-sm text-red-500">
                      {formErrors.form_remarks}
                    </p>
                  )}
                </div>
              </>
            )}
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Date of Deposit
              </label>
              <CustomCalendarPicker
                dateFromHubs={dateFromHubs}
                value={formData.date}
                onChange={(value) =>
                  handleInputChange({ target: { name: "date", value } })
                }
                className="block w-full bg-[#1F1F1F] border border-[#373737] rounded-md shadow-sm p-2 text-white"
                error={formErrors.date}
              />
              {formErrors.date && (
                <p className="mt-1 text-sm text-red-500">{formErrors.date}</p>
              )}
            </div>
          </div>
        </>
      )}
      {showResaonForNoDiposit ? (
        <button
          className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
          onClick={handleSubmiButtonWithoutImage}
        >
          Submit
        </button>
      ) : (
        <button
          onClick={handleNextClick}
          className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
          disabled={isLoading}
        >
          Next
        </button>
      )}
    </>
  );

  const renderImageUpload = () => (
    <>
      <h3 className="text-xl font-medium mb-4 text-gray-200">
        <div className="flex w-full justify-between items-center">
          <p
            className="text-sm font-regular text-gray-100 opacity-65"
            style={{ fontSize: "0.75rem" }}
          >
            Step 2 of 2
          </p>
          <p
            className={`text-[16px] font-regular text-gray-100 opacity-65 flex gap-1 items-center ${isUploading || isAnalyzing
              ? "cursor-not-allowed"
              : "cursor-pointer"
              }`}
            onClick={() => {
              if (!isUploading && !isAnalyzing) setCurrentStep(1);
            }}
            disabled={isUploading || isAnalyzing}
          >
            <ArrowLeft size={18} />
            Back
          </p>
        </div>
        Image Upload
      </h3>
      <div className="mb-6">
        <label
          htmlFor="file-upload"
          className="block mb-2 text-sm font-medium text-gray-300"
        >
          Choose an image
        </label>
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            <Image size={26} className="mx-auto text-gray-400 mb-4" />
            <div className="flex text-sm text-gray-400">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-gray-700 rounded-md font-medium text-blue-400 hover:text-blue-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
              >
                <span className="p-2 rounded-lg">Upload a file</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="sr-only"
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-400">PNG, JPG up to 10MB</p>
          </div>
        </div>
      </div>

      {preview && (
        <div className="mb-6">
          <img
            src={preview}
            alt="Preview"
            className="max-w-full h-auto rounded-lg shadow-md"
          />
        </div>
      )}

      <div className="flex flex-col space-y-4 mb-3">
        <button
          onClick={handleUpload}
          disabled={!formData.image || isUploading || isAnalyzing}
          className={`w-full px-4 py-2 rounded-md text-white font-semibold ${formData.image && !isUploading && !isAnalyzing
            ? "bg-blue-600 hover:bg-blue-700 transition-colors"
            : "bg-gray-600 cursor-not-allowed"
            }`}
        >
          {isUploading || isAnalyzing ? "Processing..." : "Upload and Analyze"}
        </button>
      </div>

      <button
        onClick={resetImageUpload}
        disabled={isUploading || isAnalyzing}
        className={`w-full mt-4 px-4 py-2 text-gray-400 hover:text-red-300 flex items-center justify-center border border-gray-400 rounded-md transition-colors ${isUploading || isAnalyzing ? "opacity-50 cursor-not-allowed" : ""
          }`}
      >
        <XCircleIcon className="w-5 h-5 mr-1" />
        Reset Image
      </button>
    </>
  );

  // const renderAmountConfirmation = () => (
  //   <>
  //     <h3 className="text-xl font-medium mb-4 text-gray-200">
  //       <div className="flex w-full justify-between items-center">
  //         <p
  //           className="text-sm font-regular text-gray-100 opacity-65"
  //           style={{ fontSize: "0.75rem" }}
  //         >
  //           Step 3 of 3
  //         </p>
  //         <p
  //           className="text-[16px] font-regular text-gray-100 opacity-65 flex gap-1 items-center cursor-pointer"
  //           onClick={() => setCurrentStep(2)}
  //         >
  //           <ArrowLeft size={18} />
  //           Back
  //         </p>
  //       </div>
  //       Confirm Details
  //     </h3>
  //     <div className="space-y-4 mb-6">
  //       <div className="bg-gray-800 p-4 rounded-md">
  //         <p className="text-white mb-2">
  //           <span className="font-semibold">Your entered amount:</span>{" "}
  //           {formData.amount}
  //         </p>
  //         <p className="text-white mb-2">
  //           <span className="font-semibold">OCR detected amount:</span>{" "}
  //           {analysisResult?.dbInsertResult?.finalTotalAmount}
  //         </p>
  //         <p className="text-white mb-2">
  //           <span className="font-semibold">Your entered date:</span>{" "}
  //           {formatDateForDisplay(formData.date)}
  //         </p>
  //         <p className="text-white mb-2">
  //           <span className="font-semibold">OCR detected date:</span>{" "}
  //           {formatDateForDisplay(analysisResult?.llmResponse?.date)}
  //         </p>
  //         <p className="text-white mb-2">
  //           <span className="font-semibold">Your entered slip number:</span>{" "}
  //           {formData.slip_number}
  //         </p>
  //         <p className="text-white">
  //           <span className="font-semibold">OCR detected slip number:</span>{" "}
  //           {
  //             analysisResult?.dbInsertResult?.cms_slip_number ?
  //               analysisResult?.dbInsertResult?.cms_slip_number :
  //               analysisResult?.dbInsertResult?.bank_slip_number
  //           }
  //         </p>
  //       </div>
  //       {analysisResult.isRadiant && (
  //         <>
  //           <h4 className="text-white font-semibold mt-4 mb-2">
  //             Radiant Parts
  //           </h4>
  //           <div className="text-white mb-2">
  //             <h5 className="font-semibold">Part A:</h5>
  //             <ul className="list-disc pl-5">
  //               {analysisResult.partA.map((item, index) => (
  //                 <li key={index}>{item}</li>
  //               ))}
  //             </ul>
  //           </div>
  //           <div className="text-white">
  //             <h5 className="font-semibold">Part B:</h5>
  //             <ul className="list-disc pl-5">
  //               {analysisResult.partB.map((item, index) => (
  //                 <li key={index}>{item}</li>
  //               ))}
  //             </ul>
  //           </div>
  //         </>
  //       )}
  //       {(amountMismatch || dateMismatch || slipNumberMismatch) && (
  //         <div className="flex items-center text-yellow-500 bg-yellow-900 p-3 rounded-md">
  //           <AlertTriangle className="mr-2" />
  //           <div>
  //             {amountMismatch && (
  //               <p>
  //                 There is a mismatch between the entered and detected amounts.
  //               </p>
  //             )}
  //             {dateMismatch && (
  //               <p>
  //                 There is a mismatch between the entered and detected dates.
  //               </p>
  //             )}
  //             {slipNumberMismatch && (
  //               <p>
  //                 There is a mismatch between the entered and detected slip
  //                 numbers.
  //               </p>
  //             )}
  //           </div>
  //         </div>
  //       )}
  //       <div className="flex justify-between">
  //         <button
  //           onClick={() => handleConfirmation(false)}
  //           className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
  //         >
  //           Report Incorrect
  //         </button>
  //         <button
  //           onClick={() => handleConfirmation(true)}
  //           className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
  //         >
  //           Confirm Correct
  //         </button>
  //       </div>
  //     </div>
  //   </>
  // );
  const renderOnSuccess = () => (
    <div className="w-full h-[75vh] flex flex-col gap-28 pt-10 animate-slide-in-right">
      <div className="flex flex-col items-center gap-6">
        <CircleCheckBig className="w-16 h-16 text-white animate-bounce" />
        <p className="text-2xl ">Completed</p>
      </div>
      <p className="text-white text-center">
        You have successfully submitted your Information
      </p>
    </div>
  );

  return (
    <div className="max-w-md mx-auto p-6 bg-[#191919] rounded-lg shadow-xl text-gray-100">
      <h2 className="text-[16px] font-medium mb-6 text-center text-white-400">
        DeliveryPlus - COD Reconciliation
      </h2>

      {currentStep === 1 && renderReviewInformation()}
      {currentStep === 2 && renderImageUpload()}
      {currentStep === 3 && renderOnSuccess()}

      {currentStep !== 3 && !isUploading && !isAnalyzing && (
        <button
          onClick={resetForm}
          className="w-full mt-4 px-4 py-2 text-gray-400 hover:text-red-300 flex items-center justify-center border border-gray-400 rounded-md transition-colors"
        >
          <XCircleIcon className="w-5 h-5 mr-1" />
          Reset
        </button>
      )}

      {uploadStatus && (
        <div
          className={`p-4 mt-6 rounded-md ${uploadStatus.includes("failed")
            ? "bg-red-900 text-red-200"
            : "bg-green-900 text-green-200"
            }`}
        >
          {uploadStatus}
        </div>
      )}

      {analysisResult && currentStep !== 3 && (
        <div className="mt-6 p-4 bg-gray-700 rounded-md">
          <h3 className="text-xl font-semibold mb-4 text-blue-400">
            Analysis Results
          </h3>
          <pre className="whitespace-pre-wrap text-sm text-gray-300">
            {JSON.stringify(analysisResult, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default Cod;
