import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const createHubsetupExpense = async(payload) => {
    try {
        const response = await axios.post(`${BASE_URL}/expense/create`, payload)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const uploadHubSetupBill = async(payload, expense_id) => {
    try {
        const response = await axios.post(`${BASE_URL}/expense-ocr/analyze-setup/${expense_id}`, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
}