import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// This is for the hub expense; bill upload with image
export const uploadBillswithImage = async (_expenseid, formData, employee_id) => {
  console.log(_expenseid, formData, employee_id)
    try {
      const response = await axios.post(`${BASE_URL}/expense-ocr/analyze/${_expenseid}`, {
        employee_id,
        ...formData,
      });
      return response.data;
    } catch (error) {
      console.error("Error analyzing image:", error);
      return error;
    }
};

// This is for the employee expense; bill upload with & without image
export const addEmployeeExpenseBill = async(formData, expense_id) => {
  try {
    const response = await axios.post(`${BASE_URL}/expense-ocr/analyze-executive/${expense_id}`, {
      ...formData
    })
    return response;
  } catch (error) {
    throw error;
  }
}


// This is for the hub expense; reimbursement
export const crateExpense = async (formData) => {
  console.log(formData)
  try {
    const response = await axios.post(`${BASE_URL}/expense/create`, {
      ...formData,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// This is for the employee expense; reimbursement
export const createEmployeeExpenseReimbursement = async(formData) => {
  try {
    const response= await axios.post(`${BASE_URL}/employee-expense/`, formData);
    return response.data;
  } catch (error) {
    throw error
  }
}

// get bank details of the employee
export const fetchBankDetails = async(employee_id) => {
  console.log(employee_id);
  try {
    const response = await axios.get(`${BASE_URL}/employee/bank-details/${employee_id}`, {
      headers: {
        "ngrok-skip-browser-warning": "true"
      }
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching bank details:", error);
    return error;
  }
}


// export const EditBankDetails = async(formData, employee_id) => {
//   try {
//     const response = await axios.put(`${BASE_URL}/employee/bank`, {
//       ...formData,
//       employee_id
//     })
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// }

// export const createBankDetails = async(formData, employee_id) => {
//   try {
//     const response = await axios.post(`${BASE_URL}/employee/bank-account`, {
//       ...formData,
//       employee_id
//     })
//     return response;
//   } catch (error) {
//     return error;
//   }
// }


