import axios from "axios";
import { toast } from "sonner";
const API_URL = process.env.REACT_APP_API_BASE_URL;
export const analyzeImage = async (formData, base64Image) => {
  try {
    console.log("called api", `${API_URL}/ocr/analyze`);
    console.log(formData, "this is the formData");
    const response = await axios.post(`${API_URL}/ocr/analyze`, {
      ...formData,
      image: base64Image,
    });
    return response.data;
  } catch (error) {
    console.log(error.response, "error");
    toast.error(error.response || "An error occurred", {
      duration: 5000,

    });
    console.error("Error analyzing image:", error);
    throw error;
  }
};