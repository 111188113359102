import Navigation from "./routes/routes";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from 'sonner'

function App() {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
    </>
  );
}

export default App;
